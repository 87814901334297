.signin{
  background-color: #e7f4fe;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
  max-height: '100%';
  min-height: '100%';
}
/*input:focus{
  outline: none;
}*/
button, input {    
  overflow: auto!important;
  }
.Doctor_login_page{
  min-height: 88vh;
  max-height: 88vh;
}
.signin-register-btn{
  text-align: end;
}
.signin-register-btn button {
  border: none;
  font-size: 14px;
  padding: 5px 30px;
  color: #fff;
  border: 2px solid #fff;
  background-color: #105c25;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
.signin-register-btn button:hover{
  color: #eee;
  border: 2px solid #eee;
}
.Doctor_login_pageform{
  border-radius: 10px;
  background-color:rgba(255, 255, 255, 0.8) ;
  box-shadow: 3px 2px 10px rgba(0, 0, 0, 0.3);
}

.Doctor_login_pageform h3{
  font-weight: 700;
  font-size: 34px;
}
.Doctor_login_pageform span{
  color:#999;
}
.Doctor_login_pageform .email input, .Doctor_login_pageform .password input{
  padding: 13px;
  width: 100%;
  border: none;
  background-color: "#fff";
}
input:focus, button:focus{
  outline: -webkit-focus-ring-color auto 0px;
}
.Doctor_login_pageform i{
  color: #999;
  font-size: 18px;
  align-self: center;
  padding: 10px;
}
.Doctor_login_pageform .email, .Doctor_login_pageform .password{
  background-color: #fff;
  border: 2px solid #999;
}
.Doctor_login_pageform .button button{
  border: 1px solid #fff;
  background-color: #1B74EB;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  padding: 5px;
  color: #fff;
  text-align: center;
  width: 100%;
}
.Doctor_login_pageform .button button:hover{
  background-color: #1e40e9;
  cursor: pointer;
}
.Doctor_login_page .forgot_password{
  color:#1B74EB;
}
.Doctor_login_page_bg{
  text-align: center;
  align-self: center;
}
.footer-section{
  font-size: 16px;
  left: 0;
  bottom: 0;
  width: 100%;
}
.footer-section a{
  color:#000;
}
/*Extra Large Screens*/
@media (min-width: 1600px) {
	.Doctor_login_pageform{
    margin-top: 70px;
    padding: 50px;
  }
  .terms-privacy{
    display: flex;
    justify-content: flex-end;
  }
  .footer-section{
    font-size: 16px;
  position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}
/*Large Screens*/
@media (min-width: 1200px) and (max-width: 1600px) {
  .Doctor_login_pageform{
    margin-top: 75px;
    padding: 50px;
  }
  .terms-privacy{
    display: flex;
    justify-content: flex-end;
  }
  .footer-section{
    font-size: 16px;
  position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}
/*Medium Screens*/
@media (min-width: 992px) and (max-width: 1200px) { 
  .Doctor_login_page_logindetails{
    margin: 20px 50px;
  }
  .Doctor_login_pageform{
    margin-top: 80px;
    padding: 30px;
  }
  .terms-privacy{
    display: flex;
    justify-content: flex-end;
  }
  .footer-section{
    font-size: 14px;
  position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}

/*Small Screens (Portriat Mode)*/
@media (min-width: 768px) and (max-width: 992px) { 
  .Doctor_login_page{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .Doctor_login_page_logindetails{
    margin: 40px 130px;
  }
  .Doctor_login_pageform{
    margin-top: 75px;
    padding: 50px;
  }
  .Doctor_login_page_bg{
    display: none;
  }
  .terms-privacy{
    display: flex;
    justify-content: flex-end;
  }
  .footer-section{
    font-size: 14px;
  position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}

/*Extra Small Screens (Landscape Mode)*/
@media (min-width: 576px) and (max-width: 768px) { 
  
  .Doctor_login_page{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .imageLogo{
    margin-top: 80px;
    margin-left: 90px;
  }
  .Doctor_login_page_logindetails{
    margin: 40px 20px;
  }
  .Doctor_login_pageform{
    margin-top: 100px;
    padding: 40px;
  }
  .Doctor_login_page_bg{
    display: none;
  }
  .jengu_logo img{
    width: 130px;
  }
  .footer-section{
    font-size: 12px;
  position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}

/*Extra Small Screens (Portriat Mode)*/
@media (min-width:320px) and (max-width: 576px){ 
  .Doctor_login_page{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .imageLogo{
    margin-top: 80px;
    margin-left: 90px;
  }
  .Doctor_login_page_logindetails{
    margin: 40px 5px;
  }
  .Doctor_login_pageform{
    margin-top: 40px;
    padding: 30px;
  }
  .Doctor_login_page_bg{
    display: none;
  }
  .jengu_logo img{
    width: 130px;
  }
  .footer-section{
    font-size: 12px;
  position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}

