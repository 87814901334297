.doctorBody{
    background-color: #f5f9fc;
}
.search_result_box:hover{
    border:1px solid #1e40e9;
}
.loader {
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #005DD6;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
/*Extra Large Screens*/
@media (min-width: 1600px) {
    .Search_result_searchdoctor_section{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .Search_result_title{
        font-size:50px; 
        font-weight:600;
        font-family:'poppins extrabold';
        display: flex;
        justify-self: center;
    }
    .search_result_list{
        margin-right: 40px;
        margin-left: 40px;
    }
    .booknow_btn{
        bottom: 0;
    position: absolute;
    }
    .booknow_btn button{
        padding-left: 25px;
        padding-right: 25px;
    }
    .hospital_details{
        margin-top:10px;
    }
}
/*Large Screens*/
@media (min-width: 1200px) and (max-width: 1599px) {
	.Search_result_searchdoctor_section{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Search_result_title{
        font-size:50px; 
        font-weight:600;
        font-family:'poppins extrabold';
        display: flex;
        justify-self: center;
    }
    .search_result_list{
        margin-right: 30px;
        margin-left: 30px;
    }
    .booknow_btn{
        bottom: 0;
    position: absolute;
    }
    .booknow_btn button{
        padding-left: 25px;
        padding-right: 25px;
    }
    .hospital_details{
        margin-top:10px;
    }
}
/*Medium Screens*/
@media (min-width: 992px) and (max-width: 1199px) { 
    .Search_result_searchdoctor_section{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Search_result_title{
        font-size:50px; 
        font-weight:600;
        font-family:'poppins extrabold';
    }
    .search_result_list{
        margin-right: 10px;
        margin-left: 10px;
    }
    .booknow_btn{
        bottom: 0;
    position: absolute;
    }
    .booknow_btn button{
        padding-left: 25px;
        padding-right: 25px;
    }
    .hospital_details{
        margin-top:10px;
    }
}

/*Small Screens (Portriat Mode)*/
@media (min-width: 768px) and (max-width: 991px) { 
    .Search_result_searchdoctor_section{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Search_result_searchdoctor_form{
        margin-left: 200px;
        margin-right: 100px;
    }
    .Search_result_title{
        font-size:50px; 
        font-weight:600;
        font-family:'poppins extrabold';
    }
    .search_result_list{
        margin-right: 140px;
        margin-left: 140px;
    }
    .booknow_btn{
        bottom: 0;
    position: absolute;
    }
    .booknow_btn button{
        padding-left: 25px;
        padding-right: 25px;
    }
    .hospital_details{
        margin-top:10px;
    }
    
}

/*Extra Small Screens (Landscape Mode)*/
@media (min-width: 576px) and (max-width: 767px) { 
    .Search_result_navbar{
        padding: .5rem 0rem;
    }
    .Search_result_searchdoctor_section{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Search_result_title{
        font-size: 38px; 
        font-weight:600;
        font-family:'poppins extrabold';
    }
    .Search_result_jengu_image{
        display: flex;
        justify-content: center;
    }
    .Search_result_search_box{
        display: flex;
        justify-content: center;
    }
    .search_result_list{
        margin-right: 25px;
        margin-left: 25px;
    }
    .booknow_btn{
        bottom: 0;
    position: absolute;
    }
    .booknow_btn button{
        padding-left: 25px;
        padding-right: 25px;
    }
    .hospital_details{
        margin-top:10px;
    }
}

/*Extra Small Screens (Portriat Mode)*/
@media (min-width:320px) and (max-width: 575px){ 
    .Search_result_navbar{
        padding: .5rem 0rem;
    }
    .Search_result_searchdoctor_section{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Search_result_title{
        font-size:34px; 
        font-weight:600;
        font-family:'poppins extrabold';
    }
    .Search_result_jengu_image{
        display: flex;
        justify-content: center;
    }
    .Search_result_search_box{
        display: flex;
        justify-content: center;
    }
    .search_result_list{
        margin-right: 5px;
        margin-left: 5px;
    }
    .search_result_button{
        display:flex;
        justify-content:center;
        align-items:center;
        margin-top: 10px;
    }
    .booknow_btn button{
        padding-left: 15px;
        padding-right: 15px;
    }
    .hospital_details{
        margin-top: 10px;
    }
}

@media print { 
    .no-printme  { display: none;}
}